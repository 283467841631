import { Injectable } from "@angular/core";
import Intercom, {
  update as IntercomUpdate,
  shutdown as IntercomShutdown,
  show as IntercomShow,
} from "@intercom/messenger-js-sdk";
import { environment } from "../../../environments/environment";
import { Environment } from "../../../environments/environment.type";
import { getLogger } from "../utils/get-logger";
import { AuthenticationService } from "./api/authentication.service";
import { BrandService } from "./api/brand.service";
import { LanguageService } from "./language.service";

@Injectable({ providedIn: "root" })
export class IntercomService {
  private readonly logger = getLogger("IntercomService");
  private config?: Environment["intercom"] = environment.intercom;
  private isInitialized = false;

  constructor(
    private readonly authService: AuthenticationService,
    private readonly languageService: LanguageService,
    private readonly brandService: BrandService,
  ) {
    this.languageService.changed$.subscribe(({ language }) => {
      if (this.isEnabled()) {
        IntercomUpdate({ language_override: language });
      }
    });

    this.brandService.currentBrand$.subscribe(() => {
      if (this.isEnabled()) {
        void this.init();
      } else if (this.isInitialized) {
        IntercomShutdown();
        this.isInitialized = false;
      }
    });

    this.authService.logout$.subscribe(() => {
      if (this.isInitialized) {
        IntercomShutdown();
        this.isInitialized = false;
      }
    });
  }

  public async init(): Promise<void> {
    if (!this.isEnabled()) {
      this.logger.info("Skipping initialization.");
      return;
    }

    try {
      Intercom({
        app_id: this.config!.appId,
        app_base: this.config!.appBase,
        language_override: this.languageService.language,
        user_id: this.authService.userId
          ? String(this.authService.userId)
          : undefined,
        created_at: this.authService.userCreatedAt,
        email: this.authService.userEmail,
        user_hash: this.authService.userIntercomHash,
      });

      this.isInitialized = true;
    } catch (err) {
      this.logger.error("Failed to initialize Intercom", err);
    }
  }

  public isEnabled(): boolean {
    const isPartner = this.authService.isRolePartner();
    const isBrand = this.authService.isRoleBrand();
    const isFeatureEnabled = !!environment.features.intercom;
    const currentBrandId = this.brandService.currentBrand$.value?.id;
    const isBrandEnabled =
      isBrand &&
      !!environment.features.intercom?.brandIds?.includes(currentBrandId ?? -1);

    return !!this.config && isFeatureEnabled && (isPartner || isBrandEnabled);
  }

  public show(): void {
    if (this.isEnabled()) {
      IntercomShow();
    }
  }
}
