import { NgClass } from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import {
  Component,
  computed,
  inject,
  OnDestroy,
  OnInit,
  signal,
} from "@angular/core";
import { MatIcon } from "@angular/material/icon";
import {
  ActivatedRoute,
  Router,
  RouterLink,
  RouterOutlet,
} from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { BrandCampaignService } from "../../brand/brand-campaign/shared/services/brand-campaign.service";
import { PartnersInviteTrackingEvents } from "../../brand/brand-campaign/shared/tracking/partners-invite.tracking-events";
import { LangSelectorComponent } from "../../layout/lang-selector/lang-selector.component";
import { PartnerUrl } from "../../partner/partner.url";
import { TrackingService } from "../../shared/analytics/tracking.service";
import { MessageComponent } from "../../shared/components/message/message.component";
import { BrandCampaign } from "../../shared/models/brandCampaign";
import { Campaign } from "../../shared/models/campaign";
import {
  CampaignPartner,
  PartnerCampaign,
} from "../../shared/models/partnerCampaign";
import { SharedPipesModule } from "../../shared/pipes/shared-pipes.module";
import { AppDataService } from "../../shared/services/api/app-data.service";
import { AuthenticationService } from "../../shared/services/api/authentication.service";
import { CampaignService } from "../../shared/services/api/campaign.service";
import { PartnerCampaignService } from "../../shared/services/api/partner-campaign.service";
import { PartnerService } from "../../shared/services/api/partner.service";
import { UserService } from "../../shared/services/api/user.service";
import { NotificationService } from "../../shared/services/notification.service";
import { NotFoundService } from "../../shared/services/not-found.service";
import { assert } from "../../shared/utils/assert";
import { isWhiteLabel } from "../../shared/services/api/authentication.service";
import { InviteService } from "../invite.service";
import { SignUpComponent } from "../sign-up/sign-up.component";
import { PartnerAcceptCampaignComponent } from "./partner-accept-campaign/partner-accept-campaign.component";

enum InviteErrors {
  NoCampaignFoundForThisCode = "NO_CAMPAIGN_FOUND_FOR_THIS_CODE",
  AlreadyInvited = "ALREADY_INVITED",
  AlreadyAccepted = "ALREADY_ACCEPTED",
}

export type AuthSection = "login" | "signup" | "forgot";

@Component({
  standalone: true,
  selector: "app-auth-page",
  templateUrl: "./auth-page.component.html",
  styleUrls: ["./auth-page.component.scss"],
  imports: [
    TranslateModule,
    RouterOutlet,
    PartnerAcceptCampaignComponent,
    SharedPipesModule,
    SignUpComponent,
    LangSelectorComponent,
    NgClass,
    MatIcon,
    MessageComponent,
    RouterLink,
  ],
})
export class AuthPageComponent implements OnInit, OnDestroy {
  private readonly appDataService = inject(AppDataService);
  private readonly route = inject(ActivatedRoute);
  private readonly campaignService = inject(CampaignService);
  private readonly brandCampaignService = inject(BrandCampaignService);
  private readonly partnerCampaignService = inject(PartnerCampaignService);
  private readonly authService = inject(AuthenticationService);
  private readonly partnerService = inject(PartnerService);
  private readonly router = inject(Router);
  private readonly notFoundService = inject(NotFoundService);
  private readonly notificationService = inject(NotificationService);
  private readonly userService = inject(UserService);
  private readonly trackingService = inject(TrackingService);
  private readonly inviteService = inject(InviteService);
  private inviteAction?: string;

  protected readonly brandLogo = signal<string | undefined>(undefined);
  protected readonly isWhiteLabel = isWhiteLabel();
  protected campaign?: Campaign;
  protected brandCampaign?: BrandCampaign;
  protected partner?: CampaignPartner;
  protected readonly campaignNotFound = signal<boolean | undefined>(undefined);
  protected readonly isPreview = signal(false);
  protected readonly signUpBackgroundImage = signal<string | undefined>(
    undefined,
  );
  protected readonly isUnderMaintenance = signal(false);
  protected readonly layoutType = signal<"invite" | "auth" | undefined>(
    undefined,
  );
  protected readonly inviteType = signal<"sign-up" | "invite" | undefined>(
    undefined,
  );
  protected readonly errorKey = signal<string | undefined>(undefined);
  protected readonly successKey = signal<string | undefined>(undefined);
  protected readonly loadingKey = signal<string | undefined>(undefined);
  protected readonly isActionComplete = signal(false);
  protected messageType = computed(() =>
    this.errorKey() ? "error" : this.successKey() ? "success" : "info",
  );
  protected messageIcon = computed(() =>
    this.errorKey()
      ? "error_outline"
      : this.successKey()
        ? "check_circle_outline"
        : undefined,
  );

  private trackingEvent?: PartnersInviteTrackingEvents;
  private readonly subs = new Subscription();

  public async ngOnInit(): Promise<void> {
    const partnerInviteToken = this.route.snapshot.params.partner_invite_token;
    this.inviteAction =
      this.route.snapshot.queryParamMap.get("action") ?? undefined;

    this.route.data.subscribe((data) => {
      this.isPreview.set(data.scenario === "preview");
      this.layoutType.set(data.scenario);
    });

    this.appDataService.get().then((config) => {
      this.isUnderMaintenance.set(config.isUnderMaintenance);
    });

    const slug = this.route.snapshot.params.slug;

    if (slug) {
      if (this.authService.isUserLogged && !this.isPreview()) {
        void this.inviteLoggedUserHandler(slug);
        return;
      }

      this.inviteType.set("sign-up");
      await this.getBrandCampaignSummaryInfo(slug);
      return;
    }

    if (partnerInviteToken) {
      this.partnerCampaignService
        .getPartnerCampaignByInviteToken(partnerInviteToken)
        .then((campaign) => {
          this.trackInviteOpened(campaign, campaign.partner);
          this.campaignService.partnerInviteToken = partnerInviteToken;
          this.partner = campaign.partner;
          this.inviteType.set(
            campaign.partner.isProspect ? "sign-up" : "invite",
          );
          this.setCampaignData(campaign);
          if (this.inviteAction) {
            this.onEventPerformInviteAction(
              this.inviteAction,
              partnerInviteToken,
            );
          }
        })
        .catch((error) => {
          console.error(error);
          this.notificationService.error(
            "partner.campaignList.joinError.campaignNotFound",
          );
          this.notFoundService.redirect("invalid-partner-invite-token", error);
        });
    }
  }

  public ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  protected onEventPartnerSignedUp(): void {
    assert(this.partner, "Partner is not defined");
    this.trackingEvent?.partnerSignedUp(
      this.partner.id,
      this.partner.isProspect,
      this.inviteAction,
    );
  }

  protected onEventPartnerAnsweredInvite(hasAccepted: boolean): void {
    assert(this.partner, "Partner is not defined");
    if (hasAccepted) {
      this.trackingEvent?.invitationAcceptedOnLandingPage(this.partner.id);
    } else {
      this.trackingEvent?.invitationRejectedOnLandingPage(this.partner.id);
    }
  }

  private trackInviteOpened(
    campaign: PartnerCampaign,
    partner: CampaignPartner,
  ): void {
    this.trackingEvent = new PartnersInviteTrackingEvents(
      campaign,
      this.trackingService,
    );

    this.trackingEvent.invitationOpened(
      partner.id,
      partner.isProspect,
      this.inviteAction,
    );
  }

  protected async getBrandCampaignSummaryInfo(slug: string): Promise<void> {
    this.brandCampaign =
      await this.brandCampaignService.getBrandCampaignSummary(slug);

    this.campaignNotFound.set(!this.brandCampaign);
    if (this.campaignNotFound()) {
      return;
    }

    this.campaign = this.brandCampaign as Campaign;
    this.campaignService.invitedCampaignId = this.campaign.id;
    this.signUpBackgroundImage.set(this.brandCampaign.signUpBackgroundImage);
    this.brandLogo.set(this.brandCampaign.logo);
  }

  private setCampaignData(campaign: PartnerCampaign): void {
    this.campaign = campaign;
    this.campaignService.invitedCampaignId = this.campaign.id;
    this.signUpBackgroundImage.set(campaign.signUpBackgroundImage);
    this.brandLogo.set(campaign.signUpLogo ?? campaign.brand.logo);
  }

  protected async inviteLoggedUserHandler(slug: string): Promise<void> {
    const currentPartnerId = await this.partnerService.getPartnerIdFromUser();

    if (this.authService.isAnyAdmin()) {
      this.notificationService.error("partner.campaign.adminAsPartnerError");
    } else if (this.authService.isRolePartner() && !!currentPartnerId) {
      this.partnerService
        .addPartnerToCampaignFromLinkInvite(currentPartnerId, slug)
        .subscribe({
          error: (error) => {
            this.notificationService.error(this.getErrorMessage(error));
          },
          complete: () => {
            this.router.navigate([PartnerUrl.Home(currentPartnerId)]);
          },
        });
    } else if (this.authService.isRoleBrand()) {
      await this.router.navigate(["/welcome"]);
    } else if (!this.authService.isRoleAdmin()) {
      const email = this.authService.userEmail;

      assert(email, "Email is not defined");

      this.userService.addUserToCampaign(email, slug).subscribe({
        error: (error) => {
          this.notificationService.error(this.getErrorMessage(error));
        },
        complete: () => {
          this.router.navigate(["/welcome"]);
        },
      });
    }
  }

  private getErrorMessage(err: HttpErrorResponse): string {
    const error = err.error?.error;

    if (error?.key === InviteErrors.AlreadyInvited) {
      return "partner.campaignList.joinError.alreadyInvited";
    }

    if (error?.key === InviteErrors.AlreadyAccepted) {
      return "partner.campaignList.joinError.alreadyAccepted";
    }

    if (error?.key === InviteErrors.NoCampaignFoundForThisCode) {
      return "partner.campaignList.joinError.campaignNotFound";
    }

    return "shared.errorPerformingTheAction";
  }

  private async onEventPerformInviteAction(
    action: string,
    token: string,
  ): Promise<void> {
    this.loadingKey.set(`invite.${action}InProgress`);
    try {
      switch (action) {
        case "approve":
          await this.inviteService.approveCampaign(token);
          break;
        case "decline":
          await this.inviteService.declineInvitation(token);
          break;
        case "join":
          await this.inviteService.joinCampaign(token);
          break;
        default:
          this.loadingKey.set(undefined);
          return;
      }
      this.loadingKey.set(undefined);
      this.isActionComplete.set(true);

      if (action === "decline") {
        return;
      }
      if (!!this.partner && !this.partner.isProspect) {
        this.afterActionForPartner(action);
      } else {
        this.afterActionForProspect(action);
      }
    } catch (error) {
      this.errorKey.set(
        error instanceof HttpErrorResponse
          ? this.getErrorMessage(error)
          : `invite.${action}Error`,
      );
    }
  }

  private afterActionForPartner(action: string): void {
    this.successKey.set(`invite.partner${action}Success`);
    if (action !== "decline") {
      const partnerId = this.partner?.id;
      const campaignId = this.campaign?.id;
      assert(partnerId);
      assert(campaignId);

      setTimeout(async () => {
        await this.router.navigate([
          PartnerUrl.CampaignHome(partnerId, campaignId),
        ]);
      }, 6000);
    }
  }

  private afterActionForProspect(action: string): void {
    this.successKey.set(`invite.prospect${action}Success`);
    if (action !== "decline") {
      this.inviteType.set("sign-up");
    }
  }
}
